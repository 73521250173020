<template>
    <v-app>
        <v-app-bar
            app
            color="#13437e"
            dark>
            <div class="d-flex align-center justify-center">
                <v-img
                    max-width="20%"
                    src="./assets/ufn-final-branco.svg"/>
            </div>
        </v-app-bar>

        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>

export default {
  name: "App",
}
</script>
